





































































































































import Vue from 'vue';
import {mapGetters} from 'vuex';
import {mdiClose} from '@mdi/js';
import {mdiMagnify} from '@mdi/js';
import TextCard from '@/components/cards/TextCard.vue';

export default Vue.extend({
  name: 'CreateAppointmentModal',
  components: {
    TextCard
  },
  data() {
    return {
        dialog: true,
        approveDialog: false,
        sameAppointmentStatus: '',
        closeIcon: mdiClose,
        mdiMagnify: mdiMagnify,
        allowedToClose: true,
        firstName: undefined,
        lastName: undefined,
        chartId: undefined,
        patient: undefined,
        candidates: [],
        loading: false,
    }
  },
  created() {
  },
  watch: {
      createAppointmentModal() {
          this.dialog = this.createAppointmentModal;
      },
      patientCandidates() {
          this.candidates = this.patientCandidates;
      }
  },
  computed: {
    ...mapGetters(['createAppointmentModal', 'patientCandidates', 'appointmentsForManualCheck']),
  },
  methods: {
    closeModal() {
      if (this.allowedToClose) {
        this.$store.dispatch('TOGGLE_CREATE_APPOINTMENT_MODAL');
      }
    },
    setAllowedToClose(value: boolean) {
        this.allowedToClose = value;
    },
    async searchCandidates() {

        if (
            [undefined, null, ''].includes(this.chartId)
            && [undefined, null, ''].includes(this.firstName)
            && [undefined, null, ''].includes(this.lastName)
        ) {
            console.log('When Chart ID wasn\'t filled firstname or lastname is required');
            this.$eventBus.$emit('emit-error', {state: true, text: `When Chart ID wasn\'t filled firstname or lastname is required`});
            return;
        }

        const payload = {
            data: {
                firstName: this.firstName,
                lastName: this.lastName,
                chartId: this.chartId,
            }
        };

        this.loading = true;
        const result = await this.$store.dispatch('GET_PATIENT_CANDIDATES', payload);

        if (result) {
            this.loading = false;
        } else {
            this.loading = false;
            this.$eventBus.$emit('emit-error', {state: true, text: `Error while searching patient`});
        }

    },
    checkAppointmentBeforeCreating() {
        const checkUp = this.isPatientOnWaitingToday();
        console.log('checkAppointmentBeforeCreating - checkUp', checkUp);

        if (checkUp.isOnWaiting) {
            const status = {
                'waiting': 'Arrived',
                'pending': 'Upcoming'
            }

            console.log('checkAppointmentBeforeCreating - checkUp - status', status[checkUp.status]);
            this.approveDialog = true;
            this.sameAppointmentStatus = status[checkUp.status];
            return false;
        } else {
            this.createAppointment();
        }
    },
    async createAppointment() {

        const payload = {
            data: {
                patient_id: this.patient.id,
                patient_name: this.patient.name,
            }
        };

        this.loading = true;
        const result = await this.$store.dispatch('CREATE_APPOINTMENT_MANUALLY', payload);

        if (result) {
            this.loading = false;
            await this.$store.dispatch('TOGGLE_CREATE_APPOINTMENT_MODAL');
        } else {
            this.loading = false;
            this.$eventBus.$emit('emit-error', {state: true, text: `Error while creating appointment!`});
        }
    },
    isPatientOnWaitingToday() {
        let isOnWaiting = false;
        let status = '';

        this.appointmentsForManualCheck?.map(({patient, status: appointmentStatus}) => {
            if (patient.external_id === this.patient.id) {
                isOnWaiting = true;
                status = appointmentStatus;
            }
        });

        return { isOnWaiting, status };
    }
  }
})
